import React, { Component } from 'react'

// PROPERTY PICTURES ////////
import street from '../images/street.jpg'
import kitchen from '../images/DSC_4141.jpg'
import laundry from '../images/DSC_4145.jpg'
import view1 from '../images/DSC_4159.jpg'
import view2 from '../images/DSC_4165.jpg'
import livingRoom from '../images/property/living-room.jpg'
import pool from '../images/property/pool-1.jpg'
import bedRoom from '../images/property/bed-room-1.jpg'
////////////////////////////////

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap'

const items = [
  {
    src: street,
    altText: 'Slide 1',
    caption: 'Costa Rican Villa',
  },
  {
    src: kitchen,
    altText: 'Slide 2',
    caption: 'Costa Rican Villa',
  },
  {
    src: pool,
    altText: 'Slide 3',
    caption: 'Costa Rican Villa',
  },
  {
    src: view1,
    altText: 'Slide 4',
    caption: 'Costa Rican Villa',
  },
  {
    src: view2,
    altText: 'Slide 5',
    caption: 'Costa Rican Villa',
  },
  {
    src: livingRoom,
    altText: 'Slide 6',
    caption: 'Costa Rican Villa',
  },
  {
    src: bedRoom,
    altText: 'Slide 7',
    caption: 'Costa Rican Villa',
  },
  {
    src: laundry,
    altText: 'Slide 8',
    caption: 'Costa Rican Villa',
  },
];

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const slides = items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} />

          {/*Add back for image specific text and title*/}
          {/*<CarouselCaption captionText={item.caption} captionHeader={item.caption} />*/}
          <CarouselCaption />
        </CarouselItem>
      )
    })

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    )
  }
}

export default Gallery
