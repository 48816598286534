import React from 'react'
import PropTypes from 'prop-types'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>Escape to Paradise</h1>
        <p>
          Reserve this beautiful Costa Rican villa and experience everything
          Costa Rica has to offer. <br />
          From its breathtaking beaches to its untouched rainforests, Costa Rica
          has somthing for everyone.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              window.location.href = 'https://drive.google.com/open?id=1RL-cN0d5FQWHRukFMN_bh1kHwkwkRhMU'
            }}
          >
          Villa Video Tour
          </a>
        </li>
        <li>
          <a
            href="javascript:"
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Villa Info
          </a>
        </li>
        <li>
          <a
            href="javascript:"
            onClick={() => {
              props.onOpenArticle('photo')
            }}
          >
            Photo Gallery
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header

// // First header
// onClick={() => {
//   props.onOpenArticle('intro')
// }}