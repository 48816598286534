import React from 'react'
import PropTypes from 'prop-types'
import List from './List'
import cr1 from '../images/bg2.jpg'
import Gallery from './Gallery'
import FullGallery from './FullGallery'
import { Link } from 'gatsby'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">The Country </h2>
          <span className="image main">
            <img src={cr1} alt="" />
          </span>
          <p>
            Costa Rica is the most visited nation in the Central American region
            for a reason. With endless sites and activies to fit anyones
            interests. Tons of things to do, close by- canaopy tours (aka
            ziplines), Jaco ropes tour, golf, Costa Rica Waterfall Tours,
            crocodile man tour, ATV tours, snorkeling, ocean kayaking, surfing
            lessons /boggy boarding, hike in the jungle, rafting, world class
            fishing.
          </p>
          <p>
            Or relax and enjoy a cocktail at Villa Caletas restaurant for world
            famous sunsets, among many other terrific restaurants.
          </p>
          <h2 className="major">Contact Us</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
            <h3> Or Contact Us Directly </h3>
            <a

            >
                <span className="label" > </span>

                <ul>
                    <li>
                        <a
                            href="mailto:myhome411@gmail.com?Subject=Costa%20Rican%20Getaway" target="_top"
                        >
                            Email: Myhome411@gmail.com
                        </a>
                    </li>
                    <li>
                        <a
                        >
                            Phone: 301-983-0069
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.jacqueshomes.com"
                        >
                            Real Estate Site
                        </a>
                    </li>
                </ul>
            </a>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/jacques.bankier"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/jacques.bankier"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/pbindustries"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">3D Tour of the Villa</h2>

            <section
                id="walkthrough"
                className="walkthrough"
                style={{ height: '400px' }}
            >
                {/*<h2 className="major">3D Tour of the Villa</h2>*/}
                <iframe
                    title="walkthrough"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://my.matterport.com/show/?m=7HTJrs831xN"
                />

                <br />
            </section>
            <br />
          <p>
            Surround yourself with luxury, privacy, with mountain views teaming
            with Costa Rican wildlife just 3 kilometers from the beach, in a secure
            gated community. Luxury two bedroom, three full bathrooms, 1,830
            square feet villa (170M2) sleeps 6 people comfortably. Both master
            suite bedrooms have luxurious bathrooms , queen size beds and
            impressive built-ins. Enjoy the air conditioning or open the wall to
            wall windows and glass doors that completely opens up the main
            level to enjoy the perfect Costa Rican climate. The first level boasts a
            living room with smart TV, cable T.V. and powerful wifi , dining room
            with large table, the fully equipped kitchen has top of the line
            stainless steel appliances with granite counter tops , exotic hard wood
            kitchen cabinets, full bath, laundry room (full size washer and dryer)
            storage room, private terrace with your own private Jacuzzi.
          </p>
          <span className="image main">
            <Gallery />
          </span>

          <section id="list" className="list">
            <br />
            <List />
            <br />
          </section>

          <section id="location" className="location">
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="yes"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2446.1784533415607!2d-84.63664995872037!3d9.706808883589597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa1c843407e90e5%3A0xd9aace29950b6d20!2sVilla+Sitio+de+Ensue%C3%B1o!5e0!3m2!1sen!2sus!4v1541183384511"
            />
            <br />
          </section>
          <br/>
          <h2 className="major">Contact Us</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
            <h3> Or Contact Us Directly </h3>
            <a

            >
                <span className="label" > </span>

                <ul>
                    <li>
                        <a
                            href="mailto:myhome411@gmail.com?Subject=Costa%20Rican%20Getaway" target="_top"
                        >
                            Email: Myhome411@gmail.com
                        </a>
                    </li>
                    <li>
                        <a
                        >
                            Phone: 301-983-0069
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.jacqueshomes.com"
                        >
                            Real Estate Site
                        </a>
                    </li>
                </ul>
            </a>
          <ul className="icons">
            <li>
              <a
                href="https://github.com/pbindustries"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/jacques.bankier"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/pbindustries"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="photo"
          className={`${this.props.article === 'photo' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Photo Gallery </h2>
          <span className="image main">
            <FullGallery />
          </span>
          <h2 className="major">Contact Us</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
            <h3> Or Contact Us Directly </h3>
            <a

            >
                <span className="label" > </span>

                <ul>
                    <li>
                        <a
                            href="mailto:myhome411@gmail.com?Subject=Costa%20Rican%20Getaway" target="_top"
                        >
                            Email: Myhome411@gmail.com
                        </a>
                    </li>
                    <li>
                        <a
                        >
                            Phone: 301-983-0069
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.jacqueshomes.com"
                        >
                            Real Estate Site
                        </a>
                    </li>
                </ul>
            </a>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/jacques.bankier"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/jacques.bankier"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/pbindustries"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        {/*<article*/}
        {/*id="contact"*/}
        {/*className={`${this.props.article === 'contact' ? 'active' : ''} ${*/}
        {/*this.props.articleTimeout ? 'timeout' : ''*/}
        {/*}`}*/}
        {/*style={{ display: 'none' }}*/}
        {/*>*/}
        {/*<h2 className="major">Contact</h2>*/}
        {/*<form*/}
        {/*name="contact"*/}
        {/*method="post"*/}
        {/*data-netlify="true"*/}
        {/*data-netlify-honeypot="bot-field"*/}
        {/*>*/}
        {/*<input type="hidden" name="bot-field" />*/}
        {/*<div className="field half first">*/}
        {/*<label htmlFor="name">Name</label>*/}
        {/*<input type="text" name="name" id="name" />*/}
        {/*</div>*/}
        {/*<div className="field half">*/}
        {/*<label htmlFor="email">Email</label>*/}
        {/*<input type="text" name="email" id="email" />*/}
        {/*</div>*/}
        {/*<div className="field">*/}
        {/*<label htmlFor="message">Message</label>*/}
        {/*<textarea name="message" id="message" rows="4" />*/}
        {/*</div>*/}
        {/*<ul className="actions">*/}
        {/*<li>*/}
        {/*<input type="submit" value="Send Message" className="special" />*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<input type="reset" value="Reset" />*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</form>*/}
        {/*<ul className="icons">*/}
        {/*<li>*/}
        {/*<a*/}
        {/*href="https://github.com/pbindustries"*/}
        {/*className="icon fa-twitter"*/}
        {/*>*/}
        {/*<span className="label">Twitter</span>*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a*/}
        {/*href="https://www.facebook.com/jacques.bankier"*/}
        {/*className="icon fa-facebook"*/}
        {/*>*/}
        {/*<span className="label">Facebook</span>*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a*/}
        {/*href="https://github.com/pbindustries"*/}
        {/*className="icon fa-instagram"*/}
        {/*>*/}
        {/*<span className="label">Instagram</span>*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*{close}*/}
        {/*</article>*/}
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Main
