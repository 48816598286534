import React from 'react'
import '../assets/scss/list.scss'

const List = props => (
  <container id="list">
    <p>
    Additionally, this community offers large swimming pool, small pool
    (perfect for young children), large gazebo style BBQ areas, sand
    beach volleyball court, dressing rooms and wonderful tot-lot. Pick
    tropical fruit right off the tree. Enjoy a huge variety of birds from the
    balcony, including large parrots, humming birds and loads of other
    tropical birds. Perfect for families with small children. Fantastic location-5 minutes to Mantas beach and Famous Blue flag Blanca beach, 15 minutes to Jaco beach (15km), and 60 minutes to
    Manuel Antonio National Park. 10 minutes to 2 fantastic golf courses
    (Marriott’s Los Suenos and La Iquana golf course) Walk to several
    restaurants and local grocery stores. 
      <br />
      <br />
      <h2> Local activities </h2>
      - Beaches: Playa Mantis and Playa Blanca (great snorkeling and boggy
        boarding) Playa Blanca was awarded the famed Blue flag beach – 5
        minutes away
      <br />
      - Motor cat to Tortuga Island from Los Suenos - 10 minutes away
      <br />
      - Upventures tours, hang out in treetop canopy - 10 minutes away
      <br />
      - Croc man tour - 10 minutes away
      <br />
      - Numerous Ziplines tours - 10 minutes away
      <br />
      - Atv tours - 2 minutes away
      <br />
      - Hiking in National park near big bridge - 15 minutes away
      <br />
      - Adventure tours across from Jaco - 15 minutes away
      <br />
      - Hiking up thru waterfalls - 10 minutes away
      <br />
      - casinos, nightclubs, boutique shops and
      tons of restaurants - Jaco 15 minutes away
      <br />
      - Nature Educational tour - 10 minutes away
      <br />
      - Hiking up through waterfalls - 10 minutes away
      <br />
      - Sup and kayak – 10 minutes away
      <br />
      - Tons of restaurants - Jaco 15 minutes away
      <br />
      - Houseback riding - 10 minutes away
      <br />
      - Large water fall – across croc man tour - 15 minutes away
      <br />
      - Festival de marsuca - awesome restaurant on water - 10 minutes away
      <br />
      - Golf at los suenos - 10 minutes away
      <br />
      - World class fishing - 10 minutes away
      <br />
      - Manuel Antonio/national park (perfect for learning surfing) - 1 hr away
      <br />
      - Rafting on pacuri river -national geographic claims “top 5 rafting
      desinations in the world” (2 hours from san Jose, towards carib side)
      <br />
      - Waterfall and monkey tours-various tours, some self-guiding
      <br />
      - Skydiving
      <br />
    </p>
  </container>
)

export default List
